import { default as addressaHvWkybw9yMeta } from "/builds/checkout/checkout-frontend/pages/checkout/[id]/address.vue?macro=true";
import { default as indextsdeoEbhIrMeta } from "/builds/checkout/checkout-frontend/pages/checkout/[id]/index.vue?macro=true";
import { default as paykM02X5b0cgMeta } from "/builds/checkout/checkout-frontend/pages/checkout/[id]/pay.vue?macro=true";
import { default as statusukcSyd68CqMeta } from "/builds/checkout/checkout-frontend/pages/checkout/[id]/status.vue?macro=true";
import { default as continueAdSD7kKECqMeta } from "/builds/checkout/checkout-frontend/pages/checkout/continue.vue?macro=true";
import { default as testumcPMR5uYAMeta } from "/builds/checkout/checkout-frontend/pages/test.vue?macro=true";
export default [
  {
    name: "checkout-id-address",
    path: "/checkout/:id()/address",
    component: () => import("/builds/checkout/checkout-frontend/pages/checkout/[id]/address.vue").then(m => m.default || m)
  },
  {
    name: "checkout-id",
    path: "/checkout/:id()",
    component: () => import("/builds/checkout/checkout-frontend/pages/checkout/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-id-pay",
    path: "/checkout/:id()/pay",
    component: () => import("/builds/checkout/checkout-frontend/pages/checkout/[id]/pay.vue").then(m => m.default || m)
  },
  {
    name: "checkout-id-status",
    path: "/checkout/:id()/status",
    component: () => import("/builds/checkout/checkout-frontend/pages/checkout/[id]/status.vue").then(m => m.default || m)
  },
  {
    name: "checkout-continue",
    path: "/checkout/continue",
    component: () => import("/builds/checkout/checkout-frontend/pages/checkout/continue.vue").then(m => m.default || m)
  },
  {
    name: "test",
    path: "/test",
    meta: testumcPMR5uYAMeta || {},
    component: () => import("/builds/checkout/checkout-frontend/pages/test.vue").then(m => m.default || m)
  }
]