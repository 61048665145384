import {debugMessage, defineNuxtPlugin} from "#imports";
import {createGtm, useGtm} from '@gtm-support/vue-gtm';
import {Checkout} from "~/src/checkout/types";

export default defineNuxtPlugin(nuxtApp => {
    const currentDomain = window.location.hostname;
    const isExcludedDomain = ['.lndo.site', '.test'].some(domain => currentDomain.endsWith(domain));

    nuxtApp.vueApp.use(createGtm({
        id: useRuntimeConfig().public.gtmId,
        enabled: !isExcludedDomain,
    }));

    return {
        provide: {
            enableGtmBasedOnCheckout: (checkout: Checkout): void => {
                const config = useRuntimeConfig().public;

                debugMessage('GTM: Config variable', config.gtmPreventLoadingOnVendorCheckout)

                if (config.gtmPreventLoadingOnVendorCheckout && (checkout.is_vendor_checkout || !checkout.id)) {
                    debugMessage('GTM: Prevent loading on vendor checkout')
                    return;
                }

                debugMessage('GTM: Enabling GTM')

                const gtm = useGtm();
                gtm?.enable(true);
                gtm?.dataLayer().push({
                    'event': 'dl_vendor_checkout',
                    'vendor_checkout': checkout.is_vendor_checkout ?? false,
                });
            }
        }
    }
});