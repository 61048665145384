<script lang="ts" setup>
import {NuxtError} from "#app";

const props = defineProps<{
  error: NuxtError,
}>()

onMounted(() => {
  console.warn('error', props.error);
  console.warn('json error', JSON.stringify(props.error));
})

if(props.error.statusCode == 404) {
  await navigateTo(useRuntimeConfig().public.notFoundRedirect, {external: true})
}
</script>

<template>
  <div class="bg-primary w-full min-h-[100vh] flex items-center justify-center py-6">
    <Title>{{ error.statusMessage }} | HB Marketplace</Title>
    <div class="w-full max-w-md px-4 text-center">
      <div class="">
        <img src="@/assets/logo_white.svg" class="h-10 w-full"/>
      </div>

      <div class="mt-6">
        <template v-if="error.statusCode == 404">
          <h2 class="text-2xl text-gray-100 font-medium">Sie werden weitergeleitet...</h2>
        </template>
        <template v-else>
          <h2 class="text-2xl text-gray-100 font-medium">Es ist ein Fehler aufgetreten.</h2>

          <span class="block text-lg text-gray-300">{{ error.statusMessage }}</span>

          <div class="mt-4">
            <NuxtLink :href="useRuntimeConfig().public.notFoundRedirect"
                      external>
              <BaseButton>Zurück</BaseButton>
            </NuxtLink>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

