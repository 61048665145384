<template>
  <button type="button"
          class="inline-flex items-center rounded-md border border-transparent bg-sky-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 disabled:opacity-60">
    <slot/>
  </button>

</template>

<script lang="ts" setup>

</script>
