import {defineNuxtPlugin} from "#imports";
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

export default defineNuxtPlugin((nuxtApp) => {
    const { vueApp } = nuxtApp;
    const config = useRuntimeConfig();

    Sentry.init({
        app: [vueApp],
        dsn: config.public.SENTRY_DSN,
        environment: process.dev ? 'development' : 'production',
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(useRouter())
            })
        ],
        tracesSampleRate: config.public.SENTRY_TRACES_SAMPLE_RATE ?? 1.0, // Recommended to lower in production,
        beforeSend (event, hint) {
            if (event.exception) {
                console.error(`[Exeption handled by Sentry]: (${hint.originalException})`, { event, hint });
            }

            return event;
        }
    });

    vueApp.mixin(Sentry.createTracingMixins({ trackComponents: true, timeout: 2000, hooks: ['activate', 'mount', 'update'] }));
    Sentry.attachErrorHandler(vueApp, { logErrors: false, attachProps: true, trackComponents: true, timeout: 2000, hooks: ['activate', 'mount', 'update'] });

    return {
        provide: {
            sentrySetContext: Sentry.setContext,
            sentrySetUser: Sentry.setUser,
            sentrySetTag: Sentry.setTag,
            sentryAddBreadcrumb: Sentry.addBreadcrumb,
            sentryCaptureException: Sentry.captureException
        }
    };
});
