export default defineNuxtPlugin(() => {
    return {
        provide: {
            formatMoney: (price: string | number) => {
                if (typeof price === "string" && isNaN(parseFloat(price))) {
                    return price;
                }

                let formatter = new Intl.NumberFormat(undefined, {
                    style: 'currency',
                    currency: 'EUR',
                })
                let parsedPrice = typeof price === 'string'
                    ? parseFloat(price)
                    : price;

                return formatter.format(parsedPrice);
            }
        }
    }
})