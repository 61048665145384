import revive_payload_client_4sVQNw7RlN from "/builds/checkout/checkout-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/checkout/checkout-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/checkout/checkout-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/builds/checkout/checkout-frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/checkout/checkout-frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/checkout/checkout-frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/checkout/checkout-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/builds/checkout/checkout-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/checkout/checkout-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import environment_6jcoV7IFIi from "/builds/checkout/checkout-frontend/plugins/environment.ts";
import gtm_client_OzmBuHLRIb from "/builds/checkout/checkout-frontend/plugins/gtm.client.ts";
import moneyFormatter_T1WE8meYMK from "/builds/checkout/checkout-frontend/plugins/moneyFormatter.ts";
import sentry_3AyO8nEfhE from "/builds/checkout/checkout-frontend/plugins/sentry.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  environment_6jcoV7IFIi,
  gtm_client_OzmBuHLRIb,
  moneyFormatter_T1WE8meYMK,
  sentry_3AyO8nEfhE
]