export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"rel":"icon","type":"image/png","href":"/favicon.png"}],"style":[],"script":[{"src":"https://consenttool.haendlerbund.de/app.js?apiKey=a76e681c8724af216af67ed88fd5a19e2af64026f8c0779d&domain=b4927d5","referrerpolicy":"origin"},{"src":"https://js.stripe.com/v3/"},{"src":"https://cdn.auth0.com/js/lock/12.0/lock.min.js"},{"src":"https://maps.googleapis.com/maps/api/js?key=AIzaSyBXLkDzejn1R85Qlw0wyRkwwehwYi3_FEE&libraries=places"},{"innerHTML":"window.dataLayer = window.dataLayer || [];","type":"text/javascript"}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000