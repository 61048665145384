import {defineNuxtPlugin, useRouter} from "#imports";
import {RouteRecord} from "vue-router";

export default defineNuxtPlugin(nuxtApp => {
    if(useRuntimeConfig().public.environment === 'production') {
        useRouter().getRoutes().forEach((route: RouteRecord): void => {
            if(route.meta.hideInProduction) {
                useRouter().removeRoute(route.name + '');
            }
        })
    }
})
